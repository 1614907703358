<style lang="less">
  @theme-1-color: #316df3;
  .login-page {
    .fm-input-new-prefix-prepend .iconfont {
      margin-left: 10px;
      color: @theme-1-color;
    }
    .fm-form-item .fm-form-item-label {
      display: none;
    }
    .fm-form-item .fm-input-new {
      width: 100%;
      border-radius: 3px;
      background-color: #FFF;
    }
    &.page-theme-0, &.page-theme-1 {
      .fm-form-item .fm-input-new {
        border-color: #FFF;
        &.verifier-error {
          border-color: red;
        }
      }
      .fm-checkbox {
        color: #FFF;
        & > i:first-child {
          background-color: #FFF;
          border-color: #FFF;
        }
        &.fm-checkbox-checked > i:first-child::after {
          border-color: @theme-1-color;
        }
      }
    }
  }
</style>

<style lang="less" scoped>
  @theme-1-color: #316df3;
  .login {
    height: 80px;
    margin: 0 auto;
    display: block;
  }
  .login-page {
    position: relative;
    height: 100vh;
    width: 100vw;
  }
  .login-form {
    z-index: 1;
    transition: all .3s;
    transition-timing-function: ease;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    padding: 50px 80px;
    will-change: transform, left, backdrop-filter, background-color;
    width: 400px;
  }
  .title {
    padding: 10px 0;
    border-bottom: 2px solid @theme-1-color;
    display: inline-block;
    margin: 20px 0;
  }
  .login-btn {
    font-size: 14px;
    color: #FFF;
    border-color: @theme-1-color;
    background: @theme-1-color;
  }
  .page-theme-0, .page-theme-1 {
    .link, .title {
      color: #FFF;
    }
  }
  .page-theme-2 {
    .link, .title {
      color: @theme-1-color;
    }
  }
  .page-theme-0 {
    .login-form {
      left: 100%;
      transform: translateY(-50%) translateX(-100%);
    }
  }
  .page-theme-1 {
    .login-form {
      background-color: rgba(21, 27, 38, .2);
      backdrop-filter: blur(2px);
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
  .page-theme-2 {
    .login-form {
      left: 0%;
    }
  }
  .backgrounds {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    transition: all .3s;
    transition-timing-function: ease;
    transform: translateY(0%);
    will-change: transform;
    .img {
      width: 100vw;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }
  .themes-toggle {
    position: fixed;
    right: 10px;
    top: 10px;
    display: flex;
    z-index: 2;
    .theme {
      margin-left: 10px;
      width: 35px;
      height: 16px;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid #EEE;
      &.active {
        border-color: rgb(254, 163, 7);
      }
    }
  }
  @media screen and (max-width: 600px) {
    body {
      .login-form {
        padding: 40px 20px;
        width: 90vw;
      }
      .title {
        margin: 10px 0;
      }
    }
  }
</style>

<template>
  <div class="login-page" :class="{['page-theme-' + themeType]: true}" @keyup.enter="login">
    <div class="themes-toggle">
      <div class="theme" :class="{'active': defaultThemeType === i}" @click="setTheme(i)" :key="i" v-for="i in themes" :style="{backgroundImage: 'url(/static/images/login/' + i + '.png)'}"></div>
    </div>
    <div class="backgrounds" :style="{transform: 'translateY(-' + (themeType * 100) + '%)'}">
      <div class="img" :key="i" v-for="i in themes" :style="{backgroundImage: 'url(/static/images/login/' + i + '.png)'}"></div>
    </div>
    <div class="login-form">
      <img class="login" src="/static/logo/logo.png" />
      <div class="title">帐号密码登录</div>
      <fm-form ref="verifier-form">
        <fm-form-item>
          <fm-input-new v-model="account" v-verifier required clearable placeholder="请输入帐号">
            <i class="iconfont icon-yonghu2" slot="prefix"></i>
          </fm-input-new>
        </fm-form-item>
        <fm-form-item>
          <fm-input-new v-model="password" v-verifier required clearable type="password" placeholder="请输入密码">
            <i class="iconfont icon-mima-old" slot="prefix"></i>
          </fm-input-new>
        </fm-form-item>
        <fm-form-item>
          <div style="display: flex; justify-content: space-between;">
            <fm-checkbox v-model="remember" label="自动登录"></fm-checkbox>
            <a class="link" href="javascript:;">忘记密码</a>
          </div>
        </fm-form-item>
        <fm-form-item>
          <fm-btn class="login-btn" @click="login" :loading="loading" size="large" block>登录</fm-btn>
        </fm-form-item>
        <fm-form-item>
          <div style="display: flex; justify-content: center;">
            <a target="_blank" class="link" href="https://beian.miit.gov.cn">备案号：皖ICP备2021009694号</a>
          </div>
        </fm-form-item>
      </fm-form>
    </div>
  </div>
</template>

<script>
import {
  localstorageHelper
} from '@/fmlib/index'

import {
  login,
  getLoginPubKey
} from '@/api'

import {
  rsaHelper
} from '@/fmlib'

import { v4 as uuidv4 } from 'uuid'

// let md5 = require('js-md5')

let defaultThemeType = localStorage.getItem('defaultThemeType')
let themes = [0, 1, 2]

export default {
  data () {
    return {
      themeType: defaultThemeType ? parseInt(defaultThemeType) : ~~(Math.random() * 10 % themes.length),
      defaultThemeType: parseInt(defaultThemeType),
      account: '',
      password: '',
      remember: false,
      loading: false
    }
  },
  computed: {
    themes () {
      return themes
    }
  },
  methods: {
    setTheme (type) {
      this.themeType = type
      if (this.defaultThemeType === type) {
        this.defaultThemeType = null
        localStorage.removeItem('defaultThemeType')
      } else {
        this.defaultThemeType = type
        localStorage.setItem('defaultThemeType', type)
      }
    },
    async login () {
      try {
        this.loading = true
        if (!this.$refs['verifier-form'].verifier()) {
          throw new Error('请正确填写表单')
        }
        let uuid = uuidv4()
        let pubKey = await getLoginPubKey({'rsaKey': uuid})
        const result = await login({
          phone: this.account,
          password: rsaHelper.rsaEncrypt(pubKey.rsaPubKey, this.password),
          clientType: this.$store.getters.clientType,
          rsaKey: uuid
        })
        this.loading = false
        localstorageHelper.updateData('token', result.token)
        this.$store.dispatch('authInit')
      } catch (error) {
        this.loading = false
        this.$notice.warning(error.message)
      }
    }
  }
}
</script>